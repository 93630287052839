import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn: 'https://893f2ca46bbfe64187dd0be4f4f53df5@o250524.ingest.sentry.io/4505194903961600',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.thirdPartyErrorFilterIntegration({
            filterKeys: ['autowise'],
            behaviour: 'drop-error-if-contains-third-party-frames',
        }),
    ],
    tracesSampleRate: 0.25,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
});
